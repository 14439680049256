// 1. Import the initial variables
@import "/Users/pratikamin/bulma/sass/utilities/initial-variables";
@import "/Users/pratikamin/bulma/sass/utilities/derived-variables";
@import "/Users/pratikamin/bulma/sass/utilities/mixins";


$menu-item-color: $blue !default
$menu-item-radius: $radius-small !default
$menu-item-hover-color: $text-strong !default
$menu-item-hover-background-color: $background !default
$menu-item-active-color: $link-invert !default
$menu-item-active-background-color: $link !default




$menu-list-border-left: 1px solid $border !default
$menu-list-line-height: 1.25 !default
$menu-list-link-padding: 0.7em 1em !default
$menu-nested-list-margin: 0.75em !default
$menu-nested-list-padding-left: 0.75em !default

$menu-label-color: $text-light !default
$menu-label-font-size: 0.75em !default
$menu-label-letter-spacing: 0.1em !default
$menu-label-spacing: 0.1em !default


// 6. Import the rest of Bulma


@import "/Users/pratikamin/bulma/bulma";
